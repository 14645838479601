export const ROUTES = {
	SUBDOMAINS: {
		TALENT: "talent",
		UNIMARCONI: "unimarconi"
	},
	COURSES: '/corsi',
	SUBPATHS: {
		THEORY: 'teoria',
		TEST: 'test',
		PROJECT: 'progetto',
	},
	CAREER_SERVICE: "/career-service",
	CAREER_SERVICE_BASE: "/career-service-base",
} as const;

// Type utilities
type DeepReadonly<T> = {
	readonly [P in keyof T]: T[P] extends object ? DeepReadonly<T[P]> : T[P];
};

export type Routes = DeepReadonly<typeof ROUTES>;