/* eslint-disable sonarjs/no-nested-conditional */

import {
	faBookBookmark,
	faBriefcaseBlank,
	faCalendarRange,
	faGraduationCap,
	faHome,
	faMessageDots,
	faTrophy,
	faUser
} from "@awesome.me/kit-ff2ccff083/icons/classic/regular";
import { ROUTES } from "@frontend-monorepo/shared/constants";
import { SidebarMenuItems } from "@frontend-monorepo/shared/types";

import { UserRoles } from "./types/defs";

const tutorTestExercises = [
	{ id: "f3e5592f-ab8e-4b49-bb2a-22e2707e2ccf", exerciseSlug: "test-intermedio-copywriting" },
	{ id: "09fcbbd1-7586-4d90-b881-323d9cb28dc9", exerciseSlug: "test-intermedio-introduzione-data-analysis" },
	{ id: "f2753df7-3227-4028-913d-c5e43510ab1e", exerciseSlug: "test-intermedio-introduzione-sviluppo" },
	{ id: "70d99382-c6a4-45a9-94ff-99fc15f9a2d4", exerciseSlug: "test-intermedio-introduzione-digital-marketing" },
];

const config = {
	isProduction: import.meta.env.PROD,
	apiUrl: import.meta.env.VITE_APP_API_URL,
	appUrl: import.meta.env.VITE_APP_URL,
	masterUrl: import.meta.env.VITE_APP_MASTER_URL,
	notificationsApiUrl: import.meta.env.VITE_APP_NOTIFICATION_URL,
	authenticationApiUrl: import.meta.env.VITE_APP_AUTHENTICATION_URL,
	badgeApiUrl: import.meta.env.VITE_APP_BADGE_URL,
	assetsPath: import.meta.env.VITE_BASE_URL,
	adminUrl: import.meta.env.VITE_APP_ADMIN_URL,
	cookieName: "iub_pref",
	loginUrl: import.meta.env.VITE_LOGIN_URL,
	masterSubdomain: import.meta.env.VITE_APP_MASTER_SUBDOMAIN,
	talentSubdomain: import.meta.env.VITE_APP_TALENT_SUBDOMAIN,
	circleUrl: import.meta.env.VITE_APP_CIRCLE_URL,
	circleRedirectPath: import.meta.env.VITE_APP_CIRCLE_REDIRECT_PATH,
	whatsappLink: "https://wa.me/+393891567926",
	basepath: "/",
	onboardingPath: "/onboarding/master",
	cloudinaryFetchPath: "https://res.cloudinary.com/start2impact/image/fetch/",
	avatarPlaceholderImageUrl: "https://res.cloudinary.com/start2impact/image/uplo…v1666605907/Piattaforma/Img_Profilo-04_envhln.svg",
	basePlans: ["m1-o30", "m1-o31", "m1-o34", "m1-o40"],
	googleAnalyticsTrackerId: "UA-94118148-1",
	tutorAImessageMaxLenght: 50000,
	userProfilePicPlaceholder: "https://res.cloudinary.com/start2impact/image/upload/v1666605907/Piattaforma/Img_Profilo-04_envhln.svg",
	coachMessagesAuthorizedPlans: ["m1-o55", "m1-o60", "m1-o32", "m1-o33", "m1-o35", "m1-o37", "m1-o36", "m1-o50", "m2-o01"],
	// TEST
	tutorTestExercises: tutorTestExercises,
	getMenuItems: (plan = "m1-o30", role: UserRoles = "student"): SidebarMenuItems[] => {
		const { host } = window.location;
		if (host?.includes(config.masterSubdomain)) {
			return [
				{
					id: 1,
					name: "Home",
					title: "Home | start2impact",
					pathname: "/",
					to: "/",
					active: true,
					isInternal: true,
					icon: faHome,
				},
				{
					id: 4,
					name: "Gruppo Studio",
					title: "Gruppo Studio | start2impact",
					pathname: "/gruppo-studio",
					to: config.circleUrl + config.circleRedirectPath,
					active: false,
					isInternal: false,
					icon: faCalendarRange,
				},
				{
					id: 2,
					name: "Career Service",
					title: "",
					pathname: "/career-service",
					to: "/career-service",
					active: false,
					isInternal: true,
					icon: faBriefcaseBlank,
				},
			];
		}
		return [
			{
				id: 1,
				name: "Home",
				title: "Home | start2impact",
				pathname: "/",
				to: "/",
				active: true,
				isInternal: true,
				icon: faHome,
			},
			{
				id: 2,
				name: "Master",
				title: "Scegli il tuo Master | start2impact",
				pathname: "/master",
				to: "/master",
				active: false,
				isInternal: true,
				icon: faGraduationCap,
			},
			{
				id: 3,
				name: "Corsi",
				title: "Tutti i Corsi | start2impact",
				pathname: "/corsi",
				to: ROUTES.COURSES,
				active: false,
				isInternal: true,
				icon: faBookBookmark,
			},
			{
				id: 4,
				name: "Classifica",
				title: "Classifica | start2impact",
				pathname: "/classifica",
				to: "/classifica",
				active: false,
				isInternal: true,
				icon: faTrophy,
			},
			{
				id: 5,
				name: "Community",
				title: "Community | start2impact",
				pathname: "/community",
				to: "/community",
				active: false,
				isInternal: true,
				icon: faMessageDots,
			},
			{
				id: 6,
				name: "Career Service",
				title: "",
				pathname: "/career-service",
				to: `${config.basePlans.includes(plan) ? "/career-service-base" : "/career-service"}`,
				active: false,
				isInternal: true,
				icon: faBriefcaseBlank,
			},
			{
				id: 7,
				name: "Account",
				title: "",
				pathname: "/account",
				to: role === "admin" ? `${config.adminUrl}${config.isProduction ? "/admin" : ""}/profile-edit` : "/subscribe/index",
				active: false,
				isInternal: false,
				icon: faUser,
			},
		];
	},
};


export default config;
